import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Cloudinary image links
const imageLinks = [
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1735995979/Amazon%20Announcement/cqcliafwjhbzneyoaulb.gif",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386260/1_nk1djc.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386261/2_kbtfgn.png",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386261/3_w4wh4q.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/4_umu1jh.png",
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/5_hqkkun.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386262/6_gj3912.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386264/7_so6ome.png", 
  "https://res.cloudinary.com/dl2c0qth2/image/upload/v1726386266/8_coovzu.png",
];

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick}></div>;
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick}></div>;
};

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animationClasses, setAnimationClasses] = useState([]);

  useEffect(() => {
    const initialClasses = Array(imageLinks.length).fill("zoom-out");
    initialClasses[currentSlide] = "zoom-in";
    setAnimationClasses(initialClasses);
  }, [currentSlide]);

  const handleBeforeChange = (oldIndex, newIndex) => {
    const updatedClasses = [...animationClasses];
    updatedClasses[oldIndex] = "swap-up-down";
    updatedClasses[newIndex] = "zoom-in";

    for (let i = 0; i < updatedClasses.length; i++) {
      if (i !== newIndex && i !== oldIndex) {
        updatedClasses[i] = "zoom-out";
      }
    }

    setAnimationClasses(updatedClasses);
    setCurrentSlide(newIndex);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: handleBeforeChange,
  };

  return (
    <section id="announcement" className="announcement">
      <div className="content">
        <div className="container">
          <Slider {...settings}>
            {imageLinks.map((img, index) => (
              <div key={index} className={animationClasses[index]}>
                <img
                  src={img}
                  alt={`Announcement slide ${index + 1}`}
                  className="img-fluid"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
